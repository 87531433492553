import React from 'react';
import Button from '../components/buttons/button';
import { Route } from '../models/site-links';
import './support.css';

const SupportPage = () => {
  return (
    <div className="min-h-screen flex flex-row items-center justify-center">
      <div className="flex flex-column items-center justify-center">
        <h2 className="text-5xl text-center text-grey-dark font-hairline font-heading">
          Patronscan Support Installer
        </h2>
        <Button
          buttonId="DownloadSupportApp"
          link={Route.PatronscanSupportInstaller}
          internal={false}
          className="mx-auto w-full"
          blue
          text="Download Remote Support"
        />
      </div>
    </div>
  );
};

export default SupportPage;
